import React from "react";
import Layout from "components/layout";
import Seo from "components/seo/seo";
import FastDeliveryForm from "components/growth/forms/live-retailers";

import bgCustomers from "images/lives/frame.png";

import BimboLogo from "images/growth-brand-logos/bimbo.png";
import CruzVerdeLogo from "images/growth-brand-logos/cruzverde.png";
import FarmatodoLogo from "images/growth-brand-logos/farmatodo.png";
import JumboLogo from "images/growth-brand-logos/jumbo.png";
import EasyLogo from "images/growth-brand-logos/easy.png";
import MercadoLibreLogo from "images/growth-brand-logos/merecadolibre.png";
import NestleLogo from "images/growth-brand-logos/nestle.png";
import OxxoLogo from "images/growth-brand-logos/oxxo.png";
import RappiLogo from "images/growth-brand-logos/rappi.png";
import TugoLogo from "images/growth-brand-logos/tugo.png";

import ListCheckIcon from "images/list-check-icon.svg";

import LastMileImg from "images/lives/last-mile.svg";
import SameDayImg from "images/lives/same-day.svg";
import ReturnImg from "images/lives/return.svg";

import "pages/growth/fast-delivery.css";

const logos = [
  TugoLogo,
  JumboLogo,
  RappiLogo,
  EasyLogo,
  NestleLogo,
  MercadoLibreLogo,
  BimboLogo,
  CruzVerdeLogo,
  FarmatodoLogo,
  OxxoLogo,
];

const Index = () => (
  <Layout className="fast-delivery">
    <Seo 
      title='liftit' 
      description='make same day deliveries'
    />
    <div className="global-wrapper">
      <head>
        <h4>Liftit Lives</h4>
        <h1>Retailers; planificación de una venta 
en última milla.</h1>

        <p>
          <b>Desde nuestro conversatorio de automatización logística,</b>estaremos en vivo con Walmart y SMU, dos grandes retailers que han aplicado diferentes estrategias de distribución para escalar y mantener su liderazgo.
        </p>

        <ul style={{ listStyleImage: `url(${ListCheckIcon})` }}>
          <li>Entrega tus pedidos en menos tiempo.</li>
          <li>Flexibiliza tu operación y garantiza el cumplimiento.</li>
          <li>Distribuye pedidos masivos con un servicio personalizado.</li>
        </ul>
      </head>

      <div className="form">
        <FastDeliveryForm />
      </div>

      <div
        className="customers"
        style={{ backgroundImage: `url(${bgCustomers})` }}
      >
        <span>Ellos confían en nosotros</span>
        <div className="brands">
          {logos.map((logo) => (
            <img src={logo} alt={logo} />
          ))}
        </div>
      </div>

      <div className="features">
        <h4>Este evento te ayudará a potencializar tu ecommerce.</h4>

        <div className="feature">
          <img src={LastMileImg} alt="Tecnologia" />
          <h5 className="feature-title">Última milla</h5>
          <p>
          Domina grandes volúmenes de entrega con cumplimiento garantizado, brindando una experiencia de entrega a tus clientes increible.
          </p>
        </div>
        <div className="feature">
          <img src={SameDayImg} alt="Operatividad" />
          <h5 className="feature-title">Same Day</h5>
          <p>
            Flexibiliza tus entregas a través de nuestra amplia flota de
            vehículos y brinda a tus clientes una excelente experiencia de
            compra y entrega para fidelizarlos
          </p>
        </div>
        <div className="feature">
          <img src={ReturnImg} alt="Servicio" />
          <h5 className="feature-title">Logistica Inversa</h5>
          <p>
            Notifica a tus clientes el estado de la entrega, permiteles ubicar
            fácilmente sus productos y extiende tu marca través del trato
            profesionalizado de nuestros Lifters{" "}
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default Index;
