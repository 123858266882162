import React from "react";
import Person from "images/avatar.png";
import { useForm } from "react-hook-form";

const requiredMessage = "Este campo es requerido";
const emailMessage = "No es un email válido";
const phoneMessage =
  "Introduzca un número de teléfono con un mínimo de 7 dígitos.";

const portalId = "4838246";
const formGuid = "bfa008a4-3d5e-40c4-92d3-32a98aaeb9ea";

const FastDeliveryForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [leadCreated, setLeadCreated] = React.useState(false);
  const [response, setResponse] = React.useState("");

  const onSubmit = (form) => {
    const withDefault = {
      ...form,
      numero_de_empleados_en_tu_empresa: form.deliveries,
      segmento_e_commerce: form.maturity,
      servicio_de_tu_interes_: "Transporte y tecnología",
    };
    delete withDefault.deliveries;
    delete withDefault.maturity;
    const parsed = Object.keys(withDefault).reduce(
      (acc, i) => [...acc, { name: i, value: withDefault[i] }],
      []
    );

    const data = {
      fields: parsed,
    };

    const fetchData = async () => {
      const result = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      if (result.ok) {
        setLeadCreated(true);
        const json = await result.json();
        setResponse(json.inlineMessage);
      }
    };

    fetchData();
  };

  return leadCreated ? (
    <div
      className="response-text"
      dangerouslySetInnerHTML={{ __html: response }}
    ></div>
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="human-talk">
        <div className="avatar">
          <img src={Person} alt="person" />
        </div>
        <div className="information">
          <p>Reserva tu cupo aquí</p>
          <h6>Conversa con los expertos</h6>
        </div>
      </div>

      <div className="input_middle">
        <div className="input-container">
          <input
            type="text"
            placeholder="Nombre"
            className={errors.firstname && "required"}
            {...register("firstname", { required: true })}
          />
          {errors.firstname && <small>{requiredMessage}</small>}
        </div>
        <div className="input-container">
          <input
            type="text"
            placeholder="Apellido"
            className={errors.lastname && "required"}
            {...register("lastname", { required: true })}
          />
          {errors.lastname && <small>{requiredMessage}</small>}
        </div>
      </div>

      <div className="input-container">
        <input
          type="text"
          placeholder="Correo empresarial"
          className={errors.email && "required"}
          {...register("email", {
            required: true,
            pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i,
          })}
        />
        {errors.email &&
          (errors.email.type === "required" ? (
            <small>{requiredMessage}</small>
          ) : (
            <small>{emailMessage}</small>
          ))}
      </div>

      <div className="input_middle">
        <div className="input-container">
          <select
            className={errors.country && "required"}
            {...register("country", { required: true })}
          >
            <option hidden disabled selected value="">
              País
            </option>
            <option value="Colombia">Colombia</option>
            <option value="Mexico">México</option>
            <option value="Chile">Chile</option>
            <option value="Brasil">Brasil</option>
            <option value="Ecuador">Ecuador</option>
          </select>
          {errors.country && <small>{requiredMessage}</small>}
        </div>
        <div className="input-container">
          <input
            type="phone"
            min="1"
            placeholder="# de teléfono"
            className={errors.phone && "required"}
            {...register("phone", { required: true, minLength: 7 })}
          />
          {errors.phone &&
            (errors.phone.type === "required" ? (
              <small>{requiredMessage}</small>
            ) : (
              <small>{phoneMessage}</small>
            ))}
        </div>
      </div>

      <div className="input">
        <div className="input-container">
          <input
            type="text"
            placeholder="Nombre de la empresa"
            className={errors.company && "required"}
            {...register("company", { required: true })}
          />
          {errors.company && <small>{requiredMessage}</small>}
        </div>
      </div>

      <div className="input">
        <div className="input-container">
          <select
            className={errors.deliveries && "required"}
            {...register("deliveries", { required: true })}
          >
            <option hidden disabled selected value="">
              Entregas al mes
            </option>
            <option label="1 a 50 " value="1 a 50">
              1 a 50
            </option>
            <option label="51 a 500" value="51 a 500">
              51 a 500
            </option>
            <option label="501 a 1000" value="501 a 1000">
              501 a 1000
            </option>
            <option label=" Más de 1000" value="Más de 1000">
              Más de 1000
            </option>
          </select>
          {errors.deliveries && <small>{requiredMessage}</small>}
        </div>
      </div>

      <div className="input">
        <div className="input-container">
          <select
            className={errors.maturity && "required"}
            {...register("maturity", { required: true })}
          >
            <option hidden disabled selected value="">
              Nivel de madurez de tu e-commerce
            </option>
            <option label="Entrante (Iniciando tu e-Commerce)" value="Entrante">
              Entrante (Iniciando tu e-Commerce)
            </option>
            <option
              label="Emergente (e-Commerce en crecimiento)"
              value="Emergente"
            >
              Emergente (e-Commerce en crecimiento)
            </option>
            <option label="Maduro (e-Commerce posicionado)" value="Maduro">
              Maduro (e-Commerce posicionado)
            </option>
          </select>
          {errors.maturity && <small>{requiredMessage}</small>}
        </div>
      </div>

      <div className="input">
        <div className="input-container">
          <select
            className={errors.industry && "required"}
            {...register("industry", { required: true })}
          >
            <option hidden disabled selected value="">
              Industria
            </option>

            <option
              label="Grandes e-commerce"
              value="electrodomesticos_tecnologia"
            >
              Grandes e-commerce
            </option>
            <option label="Electrodomésticos(Nevera, lavadora, secadora, etc)" value="hogar_aseo">
            Electrodomésticos(Nevera, lavadora, secadora, etc)
            </option>
            <option
              label="Mejoras del hogar"
              value="ferreteria_construccion_autopartes"
            >
              Mejoras del hogar
            </option>
            <option
              label="Consumo electrónico(pequeños perifericos)"
              value="productos_para_animales_mascotas"
            >
              Consumo electrónico(pequeños perifericos)
            </option>
            <option
              label="Moda y cuidado personal"
              value="moda_cuidado_personal"
            >
              Moda y cuidado personal
            </option>
            <option
              label="Consumo(FMCGs)"
              value="Oficina_libros_papelería_jugueteria"
            >
              Consumo(FMCGs)
            </option>
            <option label="Empresas de mensajería" value="Ecommerce">
            Empresas de mensajería
            </option>
            <option
              label="Artículos deportivos"
              value="Logística y Tecnología"
            >
              Artículos deportivos
            </option>
            <option
              label="Artículos de oficina"
              value="Perfumería, Aromas y Esencias"
            >
              Artículos de oficina{" "}
              </option>
            <option
              label="Accesorios del hogar"
              value="Perfumería, Aromas y Esencias"
            >
              Accesorios del hogar{" "}
              </option>
            <option
              label="Piezas de automóviles"
              value="Perfumería, Aromas y Esencias"
            >
              Piezas de automóviles{" "}
            </option>
            <option label="Farmacéuticas y salud" value="otro">
            Farmacéuticas y salud
            </option>
          </select>
          {errors.industry && <small>{requiredMessage}</small>}
        </div>
      </div>

      <div className="input">
        <input type="submit" value="¡Regístrate ahora!"id="" />
      </div>
    </form>
  );
};

export default FastDeliveryForm;
